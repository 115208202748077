<template>
  <div class="steps_box" :style="style">
    <!-- 步骤条的组件 -->
    <div class="step_top">
      <!-- 顶部icon的盒子 -->
      <div v-if="!$slots.icon" class="icon_box">
        <div :style="{ border: `2px solid ${$parent.activeColor}`, color: $parent.activeColor }" v-if="index < $parent.active" :class="['beforeActive']">
          <i :class="icon_class"></i>
        </div>
        <div :style="{ backgroundColor: nowActiveColor }" v-else :class="[index === $parent.active ? 'active' : 'afterActive']">{{ index + 1 }}</div>
      </div>
      <slot name="icon"></slot>
      <!-- 步骤标题盒子 -->
      <div v-if="!$slots.title" class="title_box">{{ title }}</div>
      <div v-else class="title_box">
        <slot name="title"></slot>
      </div>

      <div v-if="$slots.btn_box || btn_txt" class="btn_box">
        <div
          :style="{ backgroundColor: nowActiveColor, cursor: index === $parent.active ? 'pointer' : 'not-allowed' }"
          v-if="!$slots.btn_box && index >= $parent.active"
          class="btn_class"
          @click="btnClickFn"
        >
          {{ btn_txt }}
        </div>
        <div class="success_btn" v-if="index < $parent.active">
          <i class="el-icon-check" :style="{ backgroundColor: beforeActiveColor }"></i>
          {{ btn_success_txt }}
        </div>
        <slot name="btn_box"></slot>
      </div>
    </div>
    <div class="step_bottom">
      <div class="step_line">
        <div :style="{ backgroundColor: beforeActiveColor }" v-if="!$slots.line && lastIndex !== index" class="line"></div>
        <slot v-if="lastIndex !== index" name="line"></slot>
      </div>
      <div class="step_description">
        <div v-if="!$slots.description" class="description">{{ description }}</div>
        <slot name="description"></slot>
      </div>
      <div class="step_btn_bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    // 步骤条的标题
    title: {
      type: String,
      default: '',
    },
    // 步骤的详细描述信息
    description: {
      type: String,
      default: '',
    },
    // 已完成步骤显示的icon的类名
    icon_class: {
      type: String,
      default: 'el-icon-check',
    },
    // 步骤条的高度
    height: {
      type: String,
      default: '124',
    },
    btn_txt: {
      type: String,
      default: '',
    },
    btn_success_txt: {
      type: String,
      default: '已完成',
    },
  },
  created() {
    this.$parent.steps.push(this);
  },
  data() {
    return {
      index: -1,
      un_active_color: '#d8d8d8',
    };
  },
  mounted() {
    this.$watch(
      'index',
      val => {
        // console.log(val, this.index);
        // console.log(this.index);
        // console.log(this.lastIndex);
      },
      { immediate: true }
    );
  },
  computed: {
    // 处理出该步骤条的文字颜色
    style() {
      let style = {};
      style.minHeight = this.height + 'px';
      return style;
    },
    lastIndex() {
      let steps = this.$parent.steps;
      return steps[steps.length - 1].index;
    },
    beforeActiveColor() {
      let res = this.index < this.$parent.active ? this.$parent.activeColor : this.un_active_color;
      return res;
    },
    nowActiveColor() {
      let res = this.index == this.$parent.active ? this.$parent.activeColor : this.un_active_color;
      return res;
    },
  },
  methods: {
    // 按钮点击事件
    btnClickFn() {
      if (this.index === this.$parent.active) {
        this.$emit('btnClickFn');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.absolute_center(@spindle_mode:center,@sideAxis_mode:center,@spindle_direction:row) {
  display: flex;
  justify-content: @spindle_mode;
  align-items: @sideAxis_mode;
  flex-direction: @spindle_direction;
}
.steps_box {
  display: flex;
  flex-direction: column;
  .step_top {
    min-height: 24px;
    .absolute_center(flex-start);
    .icon_box {
      width: 24px;
      font-weight: bold;
      .beforeActive {
        width: 24px;
        height: 24px;
        font-size: 16px;
        border-radius: 50%;
        .absolute_center();
        i {
          font-weight: bold;
        }
      }
      .active {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        .absolute_center();
      }
      .afterActive {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        .absolute_center();
      }
    }
    .title_box {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      flex: 1;
    }
    .btn_box {
      min-width: 100px;
      display: flex;
      justify-content: center;
      .btn_class {
        color: #fff;
        width: 96px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 2px;
        user-select: none;
      }
      .success_btn {
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 14px;
        i {
          width: 16px;
          height: 16px;
          font-weight: 700;
          line-height: 16px;
          text-align: center;
          font-weight: 700;
          border-radius: 50%;
          color: #fff;
          margin-right: 5px;
        }
      }
    }
  }
  .step_bottom {
    width: 100%;
    flex: 1;
    display: flex;
    padding: 10px 0;
    .step_line {
      width: 24px;
      .absolute_center();

      .line {
        width: 2px;
        height: 100%;
      }
    }
    .step_description {
      flex: 1;
    }
    .step_btn_bottom {
      width: 100px;
    }
  }
  .step_top > div:first-child,
  .step_bottom > div:first-child {
    margin-right: 10px;
  }
}
</style>
